jQuery(document).ready(function ($) {

    /**
     * 
     * Fetch all posts when the page loads
     * 
     */
    fetchAllPosts();


    /**
     * 
     * : Ajax Function to fetch all posts
     */
    function fetchAllPosts() {
        $.ajax({
            url: scripts_nonce.ajax_url,
            type: 'post',
            data: {
                action: 'fetch_all_posts'
            },
            success: function (response) {
                // Log the response to the browser console for debugging
                //console.log(response);

                // Replace the container body with all posts
                //$('.blog__ajax-display-body').html(response);


                // Reset the category dropdown
                $('#category-select').val('');

                // Reset the tag dropdown
                $('#tag-select').val('');

                // Reset the search input field
                $('.search-field').val('');


                /**
                 * 
                 * Append the filtered posts to the container
                 */
                var $mainContent = $('.blog__ajax-display-body');

                $mainContent.removeClass('fade-in');

                setTimeout(function () {
                    $mainContent.html(response).addClass('fade-in');
                }, 500);

            },
            error: function (xhr, status, error) {
                console.error(xhr.responseText);
            }
        });
    }



    /**
     * 
     * Delegate click events for Category and Tag links to the container : Sidebar
     * 
     */
    $('.blog__container-sidebar').on('click', '#category-links a', function (e) {

        /**
         * 
         * Prevent default link behavior
         */
        e.preventDefault();

        /**
         * 
         * Call filterPosts function with the clicked category
         */

        console.log($(this).data('id'));


        filterPosts('category', $(this).data('id'));
    });

    $('.blog__container-sidebar').on('click', '#tag-links a', function (e) {
        e.preventDefault();
        filterPosts('tag', $(this).data('id'));
    });



    /**
     * 
     * Delegate click events for Category and Tag links to the container : Body
     * 
     */
    $('.blog__ajax-display-body').on('click', '#category-links a', function (e) {
        e.preventDefault();
        filterPosts('category', $(this).data('id'));
    });


    $('.blog__ajax-display-body').on('click', '#tag-links a', function (e) {
        e.preventDefault();
        filterPosts('tag', $(this).data('id'));
    });




    /**
     * 
     * Event listener for the select dropdown to filter posts by category
     */
    $('#category-select').change(function (e) {
        e.preventDefault();

        var selectedCategorySlug = $(this).find(':selected').data('id');

        if (selectedCategorySlug === 'reset') {
            fetchAllPosts();
        } else {
            // Reset the tag dropdown and search input field, then filter by category
            $('#tag-select').val('');
            $('.search-field').val('');
            filterPosts('category', selectedCategorySlug);
        }
    });





    /**
     * 
     * Event listener for the select dropdown to filter posts by tag
     */
    $('#tag-select').change(function (e) {
        e.preventDefault();

        var selectedTagSlug = $(this).find(':selected').data('id');

        if (selectedTagSlug === 'reset') {
            fetchAllPosts();
        } else {
            // Reset the category dropdown and search input field, then filter by tag
            $('#category-select').val('');
            $('.search-field').val('');
            filterPosts('tag', selectedTagSlug);
        }
    });




    /**
     * 
     * : Ajax Function to filter posts by category and tags
     */
    function filterPosts(filterType, filterValue) {
        $.ajax({
            url: scripts_nonce.ajax_url,
            type: 'post',
            data: {
                action: 'filter_posts',
                filter_type: filterType,
                filter_value: filterValue
            },
            success: function (response) {
                // Log the response to the browser console for debugging
                //console.log(response);

                // Append the filtered posts to the container
                //$('.blog__ajax-display-body').html(response);


                /**
                 * 
                 * Append the filtered posts to the container
                 */
                var $mainContent = $('.blog__ajax-display-body');

                $mainContent.removeClass('fade-in');

                setTimeout(function () {
                    $mainContent.html(response).addClass('fade-in');
                }, 500);

            },
            error: function (xhr, status, error) {
                console.error(xhr.responseText);
            }
        });
    }








    /**
     * 
     * : Search the posts.
     * Attach input event listener to search field
     * 
     */
    $('.search-field').on('input', function () {
        /**
         * Get the search query from the input field
         */
        var searchQuery = $(this).val().trim();


        // Reset the dropdowns
        $('#category-select').val('');
        $('#tag-select').val('');

        /**
         * 
         * Perform AJAX search
         */
        searchPosts(searchQuery);
    });



    /**
     * 
     * : Ajax Function to perform a search
     */
    function searchPosts(searchQuery) {
        $.ajax({
            url: scripts_nonce.ajax_url,
            type: 'post',
            data: {
                action: 'search_posts', // Update the action to 'search_posts'
                search_query: searchQuery
            },
            success: function (response) {
                // Log the response to the browser console for debugging
                console.log(response);

                // Replace the container body with the search results
                $('.blog__ajax-display-body').html(response);
            },
            error: function (xhr, status, error) {
                console.error(xhr.responseText);
            }
        });
    }
});
