jQuery(document).ready(function ($) {

    /**
     * 
     * :: Search the site.
     * :: Attach input event listener to search field
     * 
     */
    $('.site__search-field').on('input', function () {
        /**
         * Get the search query from the input field
         */
        var searchQuery = $(this).val().trim();


        /**
         * 
         * Perform AJAX search
         */
        searchPostsAndPages(searchQuery);
    });



    /**
     * 
     * :: Ajax Function to perform a search
     * 
     */
    function searchPostsAndPages(searchQuery) {
        $.ajax({
            url: scripts_nonce.ajax_url,
            type: 'post',
            data: {
                action: 'search_site', // Update the action to 'search_posts'
                search_query: searchQuery
            },
            success: function (response) {
                // Log the response to the browser console for debugging
                console.log(response);


                /**
                 * 
                 * Append the filtered posts to the container
                 */
                var $mainContent = $('.search__ajax-display-body');

                $mainContent.removeClass('fade-in');

                setTimeout(function () {
                    $mainContent.html(response).addClass('fade-in');
                }, 500);

            },
            error: function (xhr, status, error) {
                console.error(xhr.responseText);
            }
        });
    }
});
